<script setup lang="ts">
import { wait } from 'lib/helpers/wait'

interface Props {
  showCheck?: boolean
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  showCheck: false,
  size: 2.2,
})
const emit = defineEmits(['complete'])
function completeAnimation() {
  wait(500).then(() => {
    emit('complete')
  })
}

watch(
  () => props.showCheck,
  (curr) => {
    if (curr)
      completeAnimation()
  },
  { immediate: true },
)
</script>

<template>
  <div
    class="loader"
    :aria-label="$t('loading')"
    :style="{ height: `${size}rem`, width: `${size}rem` }"
  >
    <span
      v-if="!showCheck"
      class="loader-spin"
    />
    <svg
      v-else
      class="checkmark"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
    >
      <path
        class="checkmark-check"
        fill="none"
        d="M14.1 27.2l7.1 7.2 16.7-16.8"
      />
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-spin {
  height: inherit;
  width: inherit;
  color: rgba(white, 0);
  position: relative;
  display: inline-block;
  border: 0.2rem solid;
  border-radius: 100%;
  animation: spin 1s linear infinite;
  border-right: 0.2rem solid var(--black);
}

.checkmark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 3.2rem;
  width: 3.2rem;
  display: block;
  stroke-width: 4;
  stroke: var(--black);
  animation: fill 0.2s ease-in-out 0.2s forwards 0.2s both;
}

.checkmark-check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.2s cubic-bezier(0.65, 0, 0.45, 1) 0.2s forwards;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
</style>
